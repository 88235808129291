<template>
    <div class="Projectcation" style="margin-top:20px;">
        <div class="Project_select">
            <span>选择批次</span>
            <el-select v-model="value" placeholder="请选择批次" size="small" @change="yearChange(value)" style="margin-right:10px">
                <el-option
                    v-for="item in listYear"
                    :key="item.value"
                    :label="item.value + '年'"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button :type="this.multipleSelection.length!=0?'primary':'info'" size="mini" @click="exportStu()">批量导出</el-button>
        </div>
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
            style="width: 100%;height:97%"
            ref="multipleTable"
            :row-key="getRowKeys"
            @selection-change="handleSelectionChange"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="selection" label="选择" :reserve-selection="true" v-model="multipleSelection" width="55"></el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="type" label="项目类型" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.type==1?'创新训练项目':scope.row.type==2?'创业训练项目':scope.row.type==3?'创业实践项目':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="priname" label="项目负责人" width="120px"></el-table-column>
            <el-table-column prop="monber" label="项目其他成员"></el-table-column>
            <el-table-column prop="tname" label="指导教师"></el-table-column>
            <el-table-column prop="rank" label="级别" width="100px">
                <template slot-scope="scope">
                    <span>{{scope.row.rank==1?'校级':scope.row.rank==2?'省级':scope.row.rank==3?'国家级':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="states" label="状态" show-overflow-tooltip width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.states==0?'未审核':scope.row.states==1?'指导教师审核通过':scope.row.states==2?'院系审核通过':scope.row.states==3?'学校审核通过':scope.row.states==7?'待评审':scope.row.states==8?'评审中':scope.row.states==9?'评审完成待申报':scope.row.states==11?'已申报':scope.row.states==12?'待中期检查':scope.row.states==13?'中期检查通过':scope.row.states==14?'中期检查未通过':scope.row.states==15?'待结项':scope.row.states==16?'结项通过':scope.row.states==17?'结项驳回':scope.row.states==20?'已过期':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="score" label="得分" width="120px">
                <template slot-scope="scope">
                    {{scope.row.states>8?scope.row.score:'未评分'}}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="fundSum" label="经费总额（元）" width="120px">
                <template slot-scope="scope">
                    <el-tag disable-transitions style="cursor: pointer;">{{scope.row.fundSum}}</el-tag>
                </template>
            </el-table-column> -->
            <el-table-column label="项目进度" width="100px">
                <template slot-scope="scope">
                    <el-button type="text" @click="Checkdule(scope.row)" size="mini">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button type="primary" @click="handleClick(scope.row)" size="mini">查看</el-button>
                    <el-button @click="comment(scope.row)" v-show="scope.row.states==1" size="mini">评语</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <div class="tcation_Popup">
            <el-dialog
                title="项目进度详情"
                :visible.sync="dialogVisible"
                width="1000px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-table 
                    :data="fundList" 
                    style="width: 100%"
                    stripe
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
                >   
                    <el-table-column type="index" width="50" label="#"></el-table-column>
                    <el-table-column prop="name" label="日志名称" width="210px"></el-table-column>
                    <el-table-column prop="attach" label="文件地址">
                        <template slot-scope="scope">
                            <a :href="scope.row.attach" target="_blank" class="aaaaa">{{scope.row.attach}}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="creatTime" label="上传时间" width="120px"></el-table-column>
                </el-table>
            </el-dialog>
            <el-dialog
                title="院系推荐意见"
                :visible.sync="dialogVisibleForm"
                width="650px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('Form')"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="opinion" label="意见内容" style="width:100%">
                        <el-input type="textarea" :rows="6" v-model="Form.opinion"></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers('Form')">确 定</el-button>
                    <el-button size="small" @click="closei('Form')">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            listYear:[],
            dialogVisibleForm:false,
            dialogVisible:false,
            value:'',
            pageSize:10,
            totalnum:0,
            currentPage:1,
            tableData:[],
            multipleSelection:[],
            Form:{
                opinion:'',
            },
            rules:{
                opinion:[
                    { required: true, message: '请输入意见内容', trigger: 'blur' }
                ],
            },
            fundList:[],
        }
    },
    methods: {
        initSelectYear() {
            //初始化"检查年份"下拉框
            let year = new Date().getFullYear()
            this.value = year
            this.listYear = [];
            for(let i=0;i<10;i++){
                this.listYear.push({value:(year - i),label:(year - i)+ "年"});
            }
        },
        addmbers(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Form.tId=this.$store.state.uid,
                    this.Form.type = 'a'
                    this.axios.updateYj(
                        this.Form
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisibleForm = false
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }
                    }).catch(err=>{

                    })
                }else{

                }
            })
        },
        getapp(){
            this.axios.yitemlist({
                params:{
                    uid:this.$store.state.uid,
                    createTime:this.value
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        //查看进度
        Checkdule(rows){
            this.axios.loglist({
                params:{
                    itemId:rows.itmId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.fundList = res.data.rows
                    this.dialogVisible=true
                }else{
                    this.fundList = []
                }
            }).catch(err=>{

            })
        },
        // 查询经费
        fund(rows){
            this.axios.yexpen({
                params:{
                    itemId:rows.itmId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.opinList = res.data.rows
                }else{
                    this.opinList = []
                }
            }).catch(err=>{

            })
        },
        //系主任意见
        comment(rows){
            this.dialogVisibleForm = true
            this.Form.itmId = rows.itmId
        },
        //关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.dialogVisibleForm = false
            this.dialogVisiblejf = false
            if(formName=='Form'){
                this.$refs[formName].resetFields();
            } 
        },
        getRowKeys(row) {
            return row.itmId
        },
         // 全选按钮
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val;
        },
        exportStu(){
            if(this.multipleSelection.length!=0){
                var arr = []
                this.multipleSelection.map(item=>{
                    arr.push(item.itmId)
                })
                this.axios.exportItems({
                    params:{
                        itmIds:arr.join(','),
                        name:this.$store.state.depName
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        window.open(res.data.msg, "_blank");
                    }else{

                    }
                }).catch(err=>{

                })
            }else{
                this.$message.error('请选择要导出的项目')
            }
        },
        //选择批次
        yearChange(val){
            this.value = val
            this.getapp()
        },
        handleClose(done){

        },
        handleClick(rows){
            this.$router.push({name:'Secondetails',query:{itemId:rows.itmId}})
        },
        handleSizeChange(val){
            console.log(this.multipleSelection)
            this.pageSize = val;
        },
        handleCurrentChange(val){
            console.log(this.multipleSelection)
            this.currentPage = val;
        },
    },
    mounted() {
        this.getapp()
    },
    created () {
        this.initSelectYear();
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tcation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tcation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tcation_Popup .el-table__body-wrapper{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-table__empty-block{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-dialog__footer{
    text-align: center;
}
.tcation_Popup .el-textarea__inner{
    resize: none;
    width: 450px;
}
.Projectcation .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
</style>